import guest from "../middleware/guest";

const routes = [
  {
    path: "/",
    redirect: "/sign_in",
  },

  {
    path: "/sign_in",
    name: "Login",
    component: () => import("../../views/public/v2/Login"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },

  {
    path: "/sign_up",
    name: "Register",
    component: () => import("../../views/public/v2/Register"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },

  {
    path: "/forgot-password",
    name: "ForgotPassword",
    component: () => import("../../views/public/v2/ForgotPassword"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },

  {
    path: "/reset-password",
    name: "ResetPassword",
    component: () => import("../../views/public/v2/ResetPassword"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },

  {
    path: "/v1/sign_in",
    name: "OldLogin",
    component: () => import("../../views/public/Login"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },

  {
    path: "/v1/sign_up",
    name: "OldRegister",
    component: () => import("../../views/public/Register"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },

  {
    path: "/v1/forgot-password",
    name: "OldForgotPassword",
    component: () => import("../../views/public/ForgotPassword"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },

  {
    path: "/v1/reset-password",
    name: "OldResetPassword",
    component: () => import("../../views/public/ResetPassword"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },

  {
    path: "/checkout/:priceId",
    name: "NewUserCheckout",
    component: () => import("../../views/public/NewUserCheckout"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },

  {
    path: "/account-setup/:token",
    name: "PaidUserAccountSetup",
    component: () => import("../../views/public/PaidUserAccountSetup"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },

  {
    path: "/authorize/:id",
    name: "AuthorizedUserAccess",
    component: () => import("../../views/public/AuthorizedUserAccess"),
    meta: {
      layout: () => import("../../layouts/public"),
      middleware: [guest],
    },
  },
];

export default routes;
