const state = {
  recordDetails: {},
  dashboardMyRecordingDetails: {},
  dashboardArchivedRecordingDetails: {},
  dashboardReceivedMyRecordingDetails: {},
  dashboardReceivedArchivedRecordingDetails: {},
  user_details: {},
  video_settings: {},
};

const getters = {
  recordDetails(state) {
    return state.recordDetails;
  },
  dashboardMyRecordingDetails(state) {
    return state.dashboardMyRecordingDetails;
  },
  dashboardArchivedRecordingDetails(state) {
    return state.dashboardArchivedRecordingDetails;
  },
  dashboardReceivedMyRecordingDetails(state) {
    return state.dashboardReceivedMyRecordingDetails;
  },
  dashboardReceivedArchivedRecordingDetails(state) {
    return state.dashboardReceivedArchivedRecordingDetails;
  },
  user_details(state) {
    return state.user_details;
  },
  video_settings(state) {
    return state.video_settings;
  },
};

const mutations = {
  SHOW_DETAILS(state, data) {
    state.recordDetails = data;
  },
  DASHBOARD_MY_RECORDINGS(state, data) {
    state.dashboardMyRecordingDetails = data;
  },
  DASHBOARD_ARCHIVED_RECORDINGS(state, data) {
    state.dashboardArchivedRecordingDetails = data;
  },
  DASHBOARD_RECEIVED_RECORDINGS(state, data) {
    state.dashboardReceivedMyRecordingDetails = data;
  },
  DASHBOARD_RECEIVED_ARCHIVED_RECORDINGS(state, data) {
    state.dashboardReceivedArchivedRecordingDetails = data;
  },
  USER_DETAILS(state, data) {
    /** Optionally store the domain details in localStorage */
    state.user_details = data;
  },
  VIDEO_SETTINGS(state, data) {
    state.video_settings = data;
  },
};

const actions = {
  async my_recordings(_, page) {
    return await window.axios.get("/recordings/my-recordings" + page);
  },
  async dashboard_my_recordings({ commit }, page) {
    const response = await window.axios.get(
      "/recordings/dashboard-my-recordings" + page
    );
    commit("DASHBOARD_MY_RECORDINGS", response.data.data);
    return response;
  },
  async dashboard_archived_recordings({ commit }, page) {
    const response = await window.axios.get(
      "/recordings/dashboard-my-recordings/yes" + page
    );
    commit("DASHBOARD_ARCHIVED_RECORDINGS", response.data.data);
    return response;
  },
  async dashboard_received_recordings({ commit }, page) {
    const response = await window.axios.get(
      "/recordings/dashboard-received-recordings" + page
    );
    commit("DASHBOARD_RECEIVED_RECORDINGS", response.data.data);
    return response;
  },
  async dashboard_received_archived_recordings({ commit }, page) {
    const response = await window.axios.get(
      "/recordings/dashboard-received-recordings/yes" + page
    );
    commit("DASHBOARD_RECEIVED_ARCHIVED_RECORDINGS", response.data.data);
    return response;
  },
  async received_recordings(_, page) {
    return await window.axios.get("/recordings/received-recordings" + page);
  },
  async requested_links(_, page) {
    return await window.axios.get("/recordings/requested-links" + page);
  },
  async delete_recording(_, record_id) {
    return await window.axios.delete("/recordings/" + record_id + "/delete");
  },
  async new_request(_, form) {
    return await window.axios.post("/recordings/new-request", form);
  },
  async get_user(_, param) {
    return await window.axios.get("/recordings/get-user/" + param);
  },
  async get_req_user_branding(_, request) {
    return await window.axios.post(
      "/recordings/get-req-user-branding",
      request
    );
  },
  async get_user_branding(_, user_id) {
    return await window.axios.get("/recordings/get-user-branding/" + user_id);
  },
  async delete_requested_link(_, link_id) {
    return await window.axios.delete("/categories/" + link_id + "/delete");
  },
  async user_can_upload(_, form) {
    return await window.axios.post("/recordings/can-user-upload", form);
  },
  async update_recording(_, form) {
    return await window.axios.patch(
      "/recordings/update-recording/" + form.id,
      form
    );
  },
  async video_settings({ commit }, recording_id) {
    const response = await window.axios.get("/videos/settings/" + recording_id);
    commit("VIDEO_SETTINGS", response.data);
    return response;
  },
  async update_video_settings({ getters }, payload) {
    const response = await window.axios.post("/videos/settings", payload);
    const recordDetails = getters.recordDetails;
    recordDetails["video_settings"] = response.data;
    return response;
  },
  async delete_selected(_, ids) {
    return await window.axios.post("/recordings/delete-multiple", ids);
  },
  async duplicate_recording(_, form) {
    return await window.axios.post(
      "/recordings/" + form.id + "/duplicate",
      form
    );
  },
  async archive_recording(_, form) {
    return await window.axios.patch("/recordings/archive-recording/" + form.id);
  },
  async unarchive_recording(_, form) {
    return await window.axios.patch(
      "/recordings/unarchive-recording/" + form.id
    );
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
